.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Ajusta el grosor de la línea */
    background: transparent;
    z-index: 9999; /* Asegúrate de que esté sobre otros elementos */
  }
  
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #3498db; /* Color de la línea */
    animation: load 2s infinite; /* Duración y repetición de la animación */
  }
  
  @keyframes load {
    0% {
      width: 0;
      transform: translateX(-100%);
    }
    50% {
      width: 100%;
      transform: translateX(0);
    }
    100% {
      width: 0;
      transform: translateX(100%);
    }
  }
  