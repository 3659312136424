@media (min-width: 192px) and (max-width: 801px) {
.postnav-container {
width: 100%;
max-width: 800px;
}
.postnav-content-byfour {
display: none;
}

.m-postnav-center-lupa {
width: 100%;
padding-top: 1%;
padding-bottom: 1%;
}

.m-lupa {
padding: 1%;
}

#searchInput {
font-family: 'Rubik', sans-serif;
width: 100%;
padding: 6px 10px;
border: none;
border-radius: 4px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
transition: width 0.3s ease; /* Transición suave de 0.3 segundos para el cambio de tamaño */
}

#searchInput:focus {
outline: none;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
width: 97%;
}

}