@media (min-width: 802px) and (max-width: 10000px) {
.index-header-background-center-color {
font-family: 'Rubik', sans-serif;
z-index: 9999;
align-items: center;
backdrop-filter: blur(5px);
padding: 0.2%;
top: 0;
left: 0%;
right: 0%;
}

.nav-container {
font-family: 'Rubik', sans-serif;
background-color: rgb(86, 115, 59);
width: 100%;
min-width: 1100px;
}

.nav-center {
justify-content: space-between;
max-width: 1300px;
min-width: 1250px;
display: flex;
align-items: center;
margin: auto;
width: 100%;
font-size: large;
}

.nav-postcenter {
justify-content: space-between;
max-width: 1300px;
display: flex;
align-items: center;
margin: auto;
width: 100%;
font-size: large;
}

.d-nav-center-left {
width: 15%;
text-align: left;
margin-left: 1%;
}

.d-nav-center-left img {
margin-top: 3%;
width: 120px;
}

.d-nav-center-center {
text-align: center;
width: 33%;
}

.d-nav-center-right {
width: 33%;
}

.m-nav-center-left {
display: none;
}

#searchInput {
font-family: 'Rubik', sans-serif;
width: 400px; /* Tamaño inicial del campo de búsqueda */
padding: 6px 10px;
border: none;
border-radius: 4px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
transition: width 0.3s ease; /* Transición suave de 0.3 segundos para el cambio de tamaño */
}

#searchInput:focus {
outline: none;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
width: 400px; /* Tamaño del campo de búsqueda al hacer clic en él */
}

/* Estilos de la barra de navegación */
nav {
text-align: center;
margin-right: 1%;
width: 100%; /* Establece el ancho deseado */
}

ul {
list-style: none; /* Elimina los estilos de viñetas en la lista */
margin: 0; /* Elimina los márgenes en la lista */
padding: 0; /* Elimina los rellenos en la lista */
}

li {
display: inline-block; /* Muestra los elementos de la lista en línea */
margin-left: 10px; /* Establece un margen a la izquierda entre los elementos */
}

li:first-child {
margin-left: 0; /* Elimina el margen a la izquierda del primer elemento */
}

li a {
position: relative; /* Posición relativa para los elementos de enlace */
color: #fff; /* Color del texto */
text-decoration: none; /* Sin decoración de texto */
padding: 5px 10px; /* Relleno interno del enlace */
}

li a::after {
content: ''; /* Crea el pseudo-elemento */
position: absolute; /* Posición absoluta para el subrayado */
left: 9px; /* Comienza desde la izquierda */
bottom: 0; /* Alínea el subrayado en la parte inferior */
width: 0; /* Inicialmente sin ancho */
height: 2px; /* Altura del subrayado */
background-color: rgb(255, 255, 255);
transition: width 0.3s; /* Transición suave para el ancho */
}

li a:hover::after {
width: 80%; /* Aumenta el ancho al 100% en el hover */
}

.m-nav-center-right {
display: none;
}

.navbar-cart-bell {
display: none;
}

}