.toggle-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.linea {
  width: 20px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  transition: 0.4s;
}

.toggle-container.activo .linea {
  width: 25px; /* Ajusta el ancho deseado para las líneas activas */
  height: 2.5px;
  margin: 2.5px 0;
}
