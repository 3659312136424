@media (min-width: 802px) and (max-width: 3000px) {

#form-container {
margin-top: 1%;
margin-bottom: 1%;
}

#form-container .center-content-backgroundcolor {
border-radius: 10px;
width: 100%;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
background-color: #f2f2f2;
}

#form-contacto {
text-align: center;
padding: 1.5%;
font-family: 'Rubik', sans-serif;
min-width: 1100px;
width: 100%;
height: 75vh;
background-color: #f2f2f2;
}

#form-contacto h2 {
font-family: 'Rubik', sans-serif;
color: black;
margin: 0;
font-size: 36px;
}

#form-contacto .form-up {
margin-left: 20%;
width: 60%;
display: flex;
justify-content: space-between;
}

.form-down .form-group {
margin-top: 1%;
margin-bottom: 1%;
width: 100%;
}

#form-contacto .form-group textarea {
width: 95%;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
height: 30vh;
}

.form-down button {
    width: 20%;
    background-color: #53842c;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-down button:hover {
background-color: #12651b;
}

}