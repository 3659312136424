.floating-card {
  display: flex;
  position: fixed;
  width: 0; /* Inicialmente configurado a 0 para que no sea visible */
  height: 100vh;
  top: 0.1%;
  right: 0; /* Alineado a la derecha */
  border: 1px solid #cccccc;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: width 1.5s ease; /* Transición en la propiedad width durante 0.5 segundos con una función de suavizado 'ease' */
}

.floating-card.active {
  width: 99%; /* Ancho completo cuando está activo */
  height: 100vh;
}
