@media (min-width: 192px) and (max-width: 801px) {
footer {
background-color: #f2f2f2;
color: #000000; /* Texto negro */
}

.footer-container {
margin-right: 8%;
margin-left: 8%;
width: 84%;
display: flex;
justify-content: space-between;
align-items: center;
}

.footer-container p {
font-size:x-small;
}

.logoblack {
margin-bottom: 5px;
margin-top: 5px;
width: 6%;
}

.footer-left-section p {
margin: 0;
}

.footer-right-section p {
margin: 0;
text-align: right;
}


}

@media (min-width: 802px) and (max-width: 3000px) {
footer {
background-color: #f2f2f2;
color: #000000; /* Texto negro */
}

.footer-container {
margin-right: 8%;
margin-left: 8%;
width: 84%;
display: flex;
justify-content: space-between;
align-items: center;
}

.logoblack {
margin-bottom: 5px;
margin-top: 5px;
width: 3%;
}

.footer-left-section p {
margin: 0;
}

.footer-right-section p {
margin: 0;
text-align: right;
}

}