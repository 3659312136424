@media (min-width: 192px) and (max-width: 801px) {
.long-banners-container {
  position: relative;
  width: 99%;
  margin: auto;
}

.mdc-offer {
width: 99%;
}

.mdc-offer img {
margin-top: 3%;
width: 100%;
}

}

@media (min-width: 802px) and (max-width: 3000px) {
.long-banners-container {
position: relative;
margin: auto;
}

.mdc-offer {
width: 100%;
max-width: 1650px;
min-width: 1250px;
}

.mdc-offer img {

width: 100%;
}
}