@media (min-width: 802px) and (max-width: 3000px) {
#productos-destacados {
text-align: center;
padding: 1.5%;
font-family: 'Rubik', sans-serif;
min-width: 1100px;
width: 100%;
}

#productos-destacados h2 {
font-family: 'Rubik', sans-serif;
color: black;
margin: 0;
font-size: 36px;
}

#recuadros-arriba,
#recuadros-abajo {
display: flex;
justify-content: space-between;
}

.recuadro {
height: 400px;
cursor: pointer;
color: #000000;
margin-top: 3.5%;
display: inline-block;
width: calc(25% - 2%);
background-color: #f2f2f2;
margin-right: 1%;
border-radius: 10px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.recuadro:last-child {
margin-right: 0;
}

#recuadros-arriba {
margin-bottom: 2%;
}

#recuadros-abajo .recuadro:nth-child(4) {
margin-right: 0;
}

.recuadro img {
margin-top: 2.5%;
width: 250px;
height: 250px;
}

.m-recuadrocontent {
display: none;
}



}