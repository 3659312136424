.ordenar-por-container {
    text-align: right;
    margin-right: 1.5%;
}

.ordenar-por-etiqueta {
    margin-right: 1%;
}

.boxy {
    margin: 0.5%;
    width: 200px;
}