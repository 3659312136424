@media (min-width: 195px) and (max-width: 801.999px) {
body {
margin: 0;
width: 100%; /* Asegura que la sección ocupe todo el ancho disponible */
}

.section {
width: 100%; /* Asegura que la sección ocupe todo el ancho disponible */
}

.left-section h1 {
display: none;
}

.left-section {
background-color: rgb(202, 221, 197);
display: flex;
flex-direction: column; /* Cambiamos la dirección de flex a columna */
justify-content: center;
align-items: center;
height: 100vh;
}

.centerform {
width: 100%;
display:grid;
justify-content: center;
}

.left-section img {
margin-left: 42.5%;
margin-top: -5%;
width: 20%;
margin-bottom: 10%;
}

.loginsection {
width: 102%;
}

.login-container {
background-color: #fff;
border-radius: 10px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
padding: 30px;
width: 300px;
display: flex;
flex-direction: column; /* Cambiamos la dirección de flex a columna */
justify-content: center;
align-items: center;
}

.login-container h2 {
text-align: center;
margin-bottom: 10%;
}

.form-group {
margin-bottom: 15px;
width: 300px;
}

.form-group label {
display: block;
font-weight: bold;
}

.form-group input {
width: 95%;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
}

.form-group button {
width: 100%;
background-color: #53842c;
color: #fff;
padding: 10px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.form-group button:hover {
background-color: #12651b;
}

.rounded-button {
background-color: #ff7f7f; /* Fondo rojo suave */
color: #fff; /* Texto blanco */
border-radius: 50px; /* Botón con bordes curvos */
padding: 2% 10%; /* Ajustamos el relleno interno del botón */
border: none; /* Sin borde */
font-size: 17px; /* Tamaño de fuente */
margin-top: 0.9%;
text-align: center;
}

.rounded-button:hover {
background-color: #ff5656; /* Cambio de color al pasar el cursor */
cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.form-group .outbutton {
margin-top: 20px;
display: flex;
justify-content: space-between;
}

.form-group .outbutton p {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton p:hover {
font-weight: bold;
}

.form-group .outbutton a {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton a:hover {
font-weight: bold;
}

.right-section {
display: none;
}

}

@media (min-width: 802px) and (max-width: 3000px) {
body {
width: 100%;
margin: 0;
}

#mb {
display: none;
}

.section {
flex: 1; /* Hace que ambas secciones ocupen igual espacio */
display: flex;
height: 99.5vh;
min-width: 1500px;
}

.left-section h1 {
display: none;
}

.left-section,
.right-section {
flex: 1; /* Divide el espacio en dos partes iguales */
}

.right-section img {
max-width: 100%;
min-width: 100%;
height: 100%;
}

.left-section {
background-color: rgb(202, 221, 197);
display: flex;
flex-direction: column; /* Cambiamos la dirección de flex a columna */
justify-content: center;
align-items: center;
min-width: 630px ;
}

.centerform {
width: 100%;
display:grid;
justify-content: center;
}

.left-section img {
margin-left: 42.5%;
margin-top: -5%;
width: 20%;
margin-bottom: 10%;
}


.loginsection {
width: 102%;
}

.login-container {
background-color: #fff;
border-radius: 10px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
padding: 30px;
width: 300px;
display: flex;
flex-direction: column; /* Cambiamos la dirección de flex a columna */
justify-content: center;
align-items: center;
}

.login-container h2 {
text-align: center;
margin-bottom: 10%;
}

.form-group {
margin-bottom: 15px;
width: 300px;
}

.form-group label {
display: block;
font-weight: bold;
}

.form-group input {
width: 95%;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
}

.form-group button {
width: 100%;
background-color: #53842c;
color: #fff;
padding: 10px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.form-group button:hover {
background-color: #12651b;
}

.rounded-button {
background-color: #ff7f7f; /* Fondo rojo suave */
color: #fff; /* Texto blanco */
border-radius: 50px; /* Botón con bordes curvos */
padding: 2% 10%; /* Ajustamos el relleno interno del botón */
border: none; /* Sin borde */
font-size: 17px; /* Tamaño de fuente */
margin-top: 0.9%;
text-align: center;
}

.rounded-button:hover {
background-color: #ff5656; /* Cambio de color al pasar el cursor */
cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.form-group .outbutton {
margin-top: 20px;
display: flex;
justify-content: space-between;
}

.form-group .outbutton p {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton p:hover {
font-weight: bold;
}

.form-group .outbutton a {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton a:hover {
font-weight: bold;
}

}

