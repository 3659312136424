.Loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.Loader p {
    align-items: center;
    text-align: center;
}

.Loader img {
    width: 5%;
    margin-top: 1%;
    margin-left: 47.5%;

}