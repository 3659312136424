@media (min-width: 802px) and (max-width: 3000px) {
.maincolumns {
font-family: 'Arial';
width: 100%;
display: flex;
flex-direction: row;
}

.cuenta-left-column {
width: 15%;
background-color: rgb(240, 240, 240);
display: grid;
margin-top: 1%;
}

.cuentas-left-column-totem {
display: flex;
flex-direction: column;
height: 100%;
padding: 7%;
}

.ontop-cuentas-left-column-totem {
margin-bottom: 20%;
}

.center-cuentas-left-column-totem {
margin-bottom: 35%;
}

.center-cuentas-left-column-totem .boton-cuenta {
margin-top: 5%;
}

.apart-cuentas-left-column-totem {
margin-top: 30%;
}


.cuenta-center-column {
margin-top: 1%;
width: 85%;
background-color: white;

}

.boton-cuenta {
text-align: left;
display: block;
width: 100%;
background-color: transparent;
border: none;
color: black;
font-family: Arial, sans-serif;
cursor: pointer;
}

.boton-cuenta:hover {
font-weight: bold;
}
}