@media (min-width: 802px) and (max-width: 3000px) {  

#aviso-container {
margin-top: 1%;
margin-bottom: 1%;
}

#aviso-container .center-content-backgroundcolor {
border-radius: 10px;
width: 100%;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
background-color: #f2f2f2;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000; /* Asegúrate de que esté sobre otros elementos */
}

.overlay-content {
background: #fff;
padding: 20px;
border-radius: 8px;
text-align: center;
}



.overlay-content p {
margin: 0;
padding: 10px;
}

.buttons {
margin-top: 20px;
}

.buttons button {
margin: 10px;
padding: 10px 20px;
border: none;
background: #007bff;
color: #fff;
border-radius: 5px;
cursor: pointer;
}

.buttons button:hover {
background: #0056b3;
}

}