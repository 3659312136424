.campos-adicionales {
    margin-top: 20px;
  }
  
  .campo {
    margin-bottom: 15px;
  }
  
  input[type="text"], input[type="number"], textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  input[type="file"] {
    display: block;
    margin-top: 10px;
  }
  
  
  