@media (min-width: 192px) and (max-width: 801px) {
.long-banners-container {
position: relative;
width: 99%;
margin: auto;
}

.banner-image {
width: 100%;
height: auto;
}

.arrow {
position: absolute;
top: 50%;
transform: translateY(-50%);
font-size: 24px;
cursor: pointer;
}

.left-arrow {
left: 10px;
}

.right-arrow {
right: 10px;
}

}

@media (min-width: 802px) and (max-width: 3000px) {
.big-banners-container {
position: relative;
max-width: 1650px;
min-width: 1250px;
height: 500px;
margin: auto;
overflow: hidden;
}

.banner-image {
width: 100%;
height: auto;
}

.arrow {
position: absolute;
top: 50%;
transform: translateY(-50%);
font-size: 24px;
cursor: pointer;
}

.left-arrow {
left: 10px;
}

.right-arrow {
right: 10px;
}

}