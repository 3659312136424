@media (min-width: 192px) and (max-width: 801px) {
body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.background {
position: relative;
width: 100%;
background-color: rgb(203, 211, 192);
}

.background-center-color {
margin-left: 1.5%;
margin-right: 1%;
}

}

@media (min-width: 802px) and (max-width: 3000px) {
body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
monospace;  
}

.maincolumns {
min-height: 85vh;
}

.center-content-1300 {
width: 100%;
max-width: 1300px;
display: flex;
align-items: center;
margin: auto;
width: 100%;
font-size: large;
}

.center1-content-1300 {
width: 100%;
max-width: 1300px;
display: flex;
align-items: center;
margin: auto;
width: 100%;
font-size: large;
}

.indexcss-margin1 {
margin-top: 0.5%; 
margin-bottom: 0.5%;
}

.background {
position: relative;
width: 100%;
background-color: rgb(203, 211, 192);
}

.background-center-color {
max-width: 1450px;
background-color: rgb(203, 211, 192);
}

.center-content-backgroundcolor {
background-color: white;
}

.maincenterbackground {
min-height: 80vh;
}

.halfsplit {
display: flex;
justify-content: space-between;
align-items: center;
text-align: center;
}

.half50 {
width: 50%;
}

}