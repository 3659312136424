.modal {
    font-family: 'Rubik', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-contenido {
    font-family: 'Rubik', sans-serif;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Estilos adicionales según sea necesario */
  