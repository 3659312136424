@media (min-width: 192px) and (max-width: 802px) {
#productos-destacados {
text-align: center;
margin-top: 3%;
font-family: 'Rubik', sans-serif;
width: 100%;
}

#productos-destacados h2 {
font-family: 'Rubik', sans-serif;
color: black;
margin: 0;
font-size: 20px;
}

#recuadros-arriba,
#recuadros-abajo {
display: flex;
justify-content: space-between;
}

.recuadro {
cursor: pointer;
color: #000000;
margin-top: 3.5%;
display: inline-block;
width: calc(50% - 2%);
background-color: #f2f2f2;
margin-right: 1%;
border-radius: 10px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.recuadro-titulo {
width: 50%;
height: 100px;
}

.recuadro p {
font-size: 12.5px;
height: 22px;
}

.recuadro h3 {
font-size:small;
}

.recuadro:last-child {
margin-right: 0;
}

#recuadros-arriba {
margin-bottom: 2%;
}

#recuadros-abajo .recuadro:nth-child(4) {
margin-right: 0;
}

.recuadro img {
margin-top: 0.5%;
width: 47.5%;
height: 47.5%;
}

.m-recuadrocontent {
margin-left: 5%;
margin-right: 5%;
align-items: center;
display: flex;
justify-content: space-between;
}

.d-recuadrocontent {
display: none;
}
}