@media (min-width: 192px) and (max-width: 801px) {
#tiendas-destacadas {
text-align: center;
margin-top: 3%;
font-family: 'Rubik', sans-serif;
width: 100%;
}

#tiendas-destacadas h2 {
font-family: 'Rubik', sans-serif;
color: black;
margin: 0;
font-size: 20px;
}


}

@media (min-width: 802px) and (max-width: 3000px) {

#tiendas-destacadas {
text-align: center;
padding: 1.5%;
font-family: 'Rubik', sans-serif;
width: 100%;
min-width: 1100px;
}

#tiendas-destacadas h2 {
font-family: 'Rubik', sans-serif;
color: black;
margin: 0;
font-size: 36px;
}



}