@media (min-width: 802px) and (max-width: 3000px) {
body {
min-width: 1250px;
margin: 0;
}

#mb {
display: none;
}

.pi-section {
display: flex;
flex-direction: column;
justify-content: right;
width: 100%;
}

.pi-section h2 {
margin-left: 10%;
display: flex;
justify-content: left;
}

#pi-formulario {
margin-left: 5%;
margin-top: 1.5%;
width: 60%;
}

.pi-container {
margin-left: 5%;
background-color: white;
border-radius: 10px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
width: 90%;
display: flex;
flex-direction: column; /* Cambiamos la dirección de flex a columna */
}

.pi-form-group {
display: flex;
flex-direction: column;
justify-content: left;
}

.pi-form-duo {
text-align: left;
background-color: white;
display: flex;
flex-direction: column;
justify-content: left;
}

.pi-form-fecha {
text-align: left;
background-color: white;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.pi-form-trio {
text-align: left;
background-color: white;
display: flex;
justify-content: space-between;
}

.pi-form-trio .pi-form-duo {
width: 30%;
}

.pi-form-fecha p {
text-align: left;
margin-top: 0.5%;
margin-bottom: 10px;
margin-left: 5px;
}

.pi-form-duo label {
margin-bottom: 5px;
margin-left: 5px;
}

.pi-form-duo input {
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
margin-bottom: 10px;
margin-left: 5px;
}

.pi-form-duo select {
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
margin-bottom: 10px;
margin-left: 5px;
}

.pi-form-duo button {
margin-left: 25%;
width: 50%;
background-color: #53842c;
color: #fff;
padding: 10px;
border: none;
border-radius: 4px;
cursor: pointer;
margin-bottom: 1.5%;
}

.pi-form-group button:hover {
background-color: #12651b;
}

.rounded-button {
background-color: #ff7f7f; /* Fondo rojo suave */
color: #fff; /* Texto blanco */
border-radius: 50px; /* Botón con bordes curvos */
padding: 2% 10%; /* Ajustamos el relleno interno del botón */
border: none; /* Sin borde */
font-size: 17px; /* Tamaño de fuente */
margin-top: 0.9%;
text-align: center;
}

.rounded-button:hover {
background-color: #ff5656; /* Cambio de color al pasar el cursor */
cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.form-group .outbutton {
margin-top: 20px;
display: flex;
justify-content: space-between;
}

.form-group .outbutton p {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton p:hover {
font-weight: bold;
}

.form-group .outbutton a {
width: 100%;
background-color: white;
font-size: smaller;
color: black;
cursor: pointer;
border: none;
text-decoration: none; 
}

.form-group .outbutton a:hover {
font-weight: bold;
}

}

