.aviso-container {
    position: relative;
  }
  
  .carrusel {
    position: relative;
    display: inline-block;
  }
  
  .imagen-carrusel {
    max-width: 100%;
    max-height: 100%;
  }
  
  .flecha-izquierda,
  .flecha-derecha {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
  }
  
  .flecha-izquierda {
    left: 10px;
  }
  
  .flecha-derecha {
    right: 10px;
  }
  