@media (min-width: 802px) and (max-width: 10000px) {

.catalogobox {
display: flex;
justify-content: center;
align-items: center;
margin-top: 0;
}

.catalogobox .recuadro {
display: grid;
grid-template-rows: 1fr auto; /* El contenido seguido del botón */
height: 300px;
cursor: default;
}
/* 
.recuadro button {
margin-bottom: 10%;
width: 60%;
background-color: #53842c;
color: #fff;
padding: 10px;
border: none;
border-radius: 4px;
cursor: pointer;
justify-self: center; 
align-self: end; 
} */

/* .prodcatalogo button:hover {
background-color: #12651b;
} */
}